// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Picture from '../../../Image/Picture';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserSubtitle from '../../../TeaserSubtitle/TeaserSubtitle';
import getImageAssets from '../../../../utils/getImageAssets';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';
import type { attrFlowType, } from '../../../../flowTypes/attrTypes';


Donbot.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
  attrs: null,
};

const teasers = [ DonbotMainTeaser, DonbotTeaser, DonbotTeaser, DonbotTeaser, DonbotTeaser, ];
const areasTemplate = [
  {
    until: 'l',
    value: `
    "he he"
    "t1 t1"
    "t2 t3"
    "t4 t5"
    `,
  },
  {
    from: 'l',
    value: `
    "he t1 t2 t3"
    "he t1 t4 t5"
    `,
  },
];

export default function Donbot({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);
  const { items, extraLinks, rainbowTargetSlot, ...restOfList } = list || {};
  if (!(items && items.length)) return null;

  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        gridArea: `t${i + 1}`,
        biAction: setBiAction(i, data, biAction),
        isLazyloadImages,
        theme,
        attrs: isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {},
      });
    }
    return null;
  }

  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[ { until: 'l', value: '1fr 1fr', }, { from: 'l', value: '2fr 6fr 2fr 2fr', }, ]}
      attrs={{
        'data-test': 'donbot',
      }}
    >
      {restOfList.title || restOfList.commercialLinks?.length || extraLinks?.length ? (
        <ListViewHeader
          {...restOfList}
          extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
          biAction={biAction}
          isSticky
        />
      ) : null}
      { rainbowTargetSlot
        ? (
          <RainbowListPaywallSlot
            id={rainbowTargetSlot}
            gridArea="t1 / t1 / t5 / t5"
            onToolRendered={onRainbowToolRendered}
          />
        )
        : null}
      <Section isFragment>{items.map(itemRenderer)}</Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

const teaserRowTemplate = 'auto 1fr auto';

type TeaserProps = {
  biAction: ?() => void,
  data: TeaserDataType,
  isLazyloadImages?: boolean,
  gridArea: string,
  theme: Object,
  attrs?: ?attrFlowType,
};

DonbotMainTeaser.defaultProps = { isLazyloadImages: true, biAction: null, attrs: null, };
export function DonbotMainTeaser({
  biAction,
  data,
  isLazyloadImages,
  theme,
  attrs,
}: TeaserProps): React.Node {
  const { mainTeaser, } = theme.donbotStyle.teasers;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      gridArea="t1"
      rowTemplate={teaserRowTemplate}
      rowGap={mainTeaser.rowGap}
      areasTemplate="'media' 'content' 'footer'"
      attrs={attrs}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: 'calc(100vw - 24px)',
              aspect: 'square',
              widths: [ 300, 352, 400, 560, ],
            },
            sources: [
              {
                aspect: 'headline',
                from: 's',
                sizes: [
                  { from: 'xl', size: '590px', },
                  { from: 'l', size: '476px', },
                  { from: 'm', size: '720px', },
                  { from: 's', size: '552px', },
                ],
                widths: [ 720, 590, 552, 476, ],
              },
            ],
          })}
        />
      </TeaserMedia>

      <TeaserContent
        tagName="header"
        isCenteredContent
        miscStyles={mainTeaser.contentMiscStyles}
      >
        <TeaserHeader
          {...data}
          typeScale={[
            { until: 's', value: 1, },
            { from: 's', until: 'l', value: 2, },
            { from: 'l', until: 'xl', value: 3, },
            { from: 'xl', value: 2, },
          ]}
          onClick={biAction}
        />
        <TeaserSubtitle
          {...data}
          typeScale={[ { until: 'xl', value: 0, }, { from: 'xl', value: -1, }, ]}
          miscStyles={{
            display: [ { until: 's', value: 'none', }, ],
            fontWeight: '400',
            paddingTop: '1rem',
          }}
        />
      </TeaserContent>
      <TeaserFooter
        data={data}
        showRank
        showAuthor
        showTime={mainTeaser.footer.showTime}
        showCommentsCount={mainTeaser.footer.showCommentsCount}
        miscStyles={{
          textAlign: 'center',
          padding: [ { until: 'xl', value: '0 2rem 2rem', }, { from: 'xl', value: '0 4rem 1rem', }, ],
        }}
      />
    </Teaser>
  );
}

DonbotTeaser.defaultProps = { biAction: null, isLazyloadImages: true, attrs: null, };
export function DonbotTeaser({
  biAction,
  data,
  gridArea,
  isLazyloadImages,
  theme,
  attrs,
}: TeaserProps): React.Node {
  const { teaser, } = theme.donbotStyle.teasers;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      gridArea={gridArea}
      rowTemplate={teaserRowTemplate}
      rowGap="1rem"
      areasTemplate="'media' 'content' 'footer'"
      attrs={attrs}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Image
          image={image}
          lazyLoad={isLazyloadImages}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'headline',
            sizes: [
              { from: 'xl', size: '178px', },
              { from: 'l', size: '143px', },
              { from: 'm', size: '348px', },
              { from: 's', size: '264px', },
              { size: 'calc(50vw - 36px)', },
            ],
            widths: [ 150, 180, 200, 285, 350, 380, 565, ],
          })}
        />
      </TeaserMedia>

      <TeaserHeader
        {...data}
        typeScale={teaser.headerTypeScale}
        onClick={biAction}
        wrapperMiscStyles={{ padding: '0 1rem', }}
      />
      <TeaserFooter
        data={data}
        miscStyles={{ padding: '0 1rem 1rem', }}
        showAuthor={teaser.footer.showAuthor}
        showCommentsCount={teaser.footer.showCommentsCount}
      />
    </Teaser>
  );
}
