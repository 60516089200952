// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { AdSlotType, } from '../../../../flowTypes/AdSlotType';
import AdSlot from '../../../AdManager/AdSlot';
import ListView from '../../../ListView/NewListView';
import useMedia from '../../../../hooks/useMedia';
import ZappItem from './ZappItem';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import useOneTime from '../../../../hooks/useOneTime';

const teasers = [ ZappItem, ZappItem, ZappItem, ZappItem, ];

Zapp.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: false,
};
export default function Zapp({
  isLazyloadImages,
  list,
  gaAction,
  biAction,
  biImpression,
  isThemarker,
  inView,
}: ListViewProps): React.Node {
  const { theme, } = useFela();

  const { banners, items, } = list || {};
  const { adSlots, } = banners || {};
  const dpfData = adSlots && adSlots[0];

  useOneTime(items && !!biImpression && typeof biImpression === 'function' && !!inView, () => items.map((item, i) => {
    i < 4 && biImpression && typeof setBiImpression === 'function' && setBiImpression(i, item, biImpression)();
    return null;
  }));

  if (!items || !items.length) return null;
  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        biAction: setBiAction(i, data, biAction),
        biImpression: setBiImpression(i, data, biImpression),
        hideImageOnMobile: false,
        isLazyloadImages,
        theme,
      });
    }
    return null;
  }
  const itemsToRender = items.slice(0, 4);

  return (
    <ListView
      innerBackgroundColor={[ { until: 's', value: 'transparent', }, { from: 's', value: 'white', }, ]}
      marginTop={[ { until: 's', value: 2, }, { from: 's', value: 0, }, ]}
      padding={theme.zappStyle.listViewPadding}
      colTemplate={[ { from: 'l', value: 'repeat(4, 1fr)', }, ]}
      attrs={{
        'data-test': 'zapp',
      }}
    >
      {itemsToRender.map(itemRenderer)}
      {dpfData ? DfpItem({ data: dpfData, theme, loadPriority: list.loadPriority, }) : null}
    </ListView>
  );
}

type DfpItemProps = {
  data: AdSlotType,
  theme: Object,
  loadPriority?: 'client' | 'lazy' | 'ssr',
};
DfpItem.defaultProps = {
  loadPriority: null,
};
function DfpItem({ data, theme, loadPriority, }: DfpItemProps): React.Node {
  const matches = useMedia({ query: { until: 's', }, matchOnServer: false, });
  const wrapperClasses = useFela().css({
    minHeight: '110px',
    gridRowStart: theme.zappStyle.dfpItemGridRowStart,
    ...theme.mq({ from: 's', }, { display: 'none', }),
    ...theme.mq({ until: 'm', }, { backgroundColor: theme.color('neutral', -6), }),
  });
  return (
    <div className={wrapperClasses}>
      {matches ? (
        <AdSlot {...data} />
      ) : null}
    </div>
  );
}
