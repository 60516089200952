// @flow
import * as React from 'react';
import { type ComponentPropResponsiveObject, } from '@haaretz/htz-css-tools';

import usePreview from '../../hooks/Page/usePreview';
import RainbowSlot from './RainbowSlot';

import type { SlotPropsType, } from './RainbowSlot';

type PropsType = SlotPropsType & {
  gridArea: string | ComponentPropResponsiveObject<string>[],
};

RainbowListPaywallSlot.defaultProps = {
  onToolRendered: null,
};

export default function RainbowListPaywallSlot({ id, gridArea, onToolRendered, }: PropsType) {
  const { isPreview, } = usePreview();

  return !isPreview ? (
    <RainbowSlot id={id} miscStyles={{ gridArea, }} onToolRendered={onToolRendered} />
  ) : null;
}
