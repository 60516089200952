// @flow
/* global window */
import React from 'react';
import { ZAPP_LIST_QUERY, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

import ZappView from './ZappView.js';

type ZappWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
};

export default function ZappWrapper(props: ZappWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={ZAPP_LIST_QUERY} view="Zapp">
      {dataProps => <ZappView {...dataProps} />}
    </ListWrapper>
  );
}
