// @flow
import type { BpsConfig, } from '@haaretz/htz-css-tools';

import getImageAssets from './getImageAssets';

import type { PicturePropsType, } from '../flowTypes/PicturePropsType';
import type { ImageDataType, } from '../flowTypes/ImageDataType';
import type { AssetOptions, } from './getImageAssets';

type SourceType = AssetOptions & {
  from?: "s" | "m" | "l" | "xl",
  until?: "s" | "m" | "l" | "xl",
  positionInImgArray?: number,
};

type DefaultImgOptionsType = AssetOptions & {
  positionInImgArray?: number,
}

type PictureAssetPropsType = {
  bps: BpsConfig,
  defaultImgOptions: DefaultImgOptionsType,
  sources: SourceType[],
  imgData?: ?ImageDataType,
  image: ?ImageDataType,
};

export default function pictureAssetProps({
  bps,
  image,
  imgData = image,
  defaultImgOptions,
  sources,
}: PictureAssetPropsType): PicturePropsType {
  return {
    image: imgData,
    defaultImg: {
      sourceOptions: getImageAssets({
        bps,
        sizes: defaultImgOptions.sizes,
        aspect: defaultImgOptions.aspect,
        widths: defaultImgOptions.widths,
      }),
      positionInImgArray: defaultImgOptions.positionInImgArray,
    },
    sources: sources.map((source: SourceType) => ({
      sourceOptions: getImageAssets({
        bps,
        sizes: source.sizes,
        aspect: source.aspect,
        widths: source.widths,
      }),
      from: source.from,
      until: source.until,
      positionInImgArray: source.positionInImgArray,
    })),
  };
}
