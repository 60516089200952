// @flow
import { imageInTeaser, } from '@haaretz/app-utils';
import * as React from 'react';
import gql from 'graphql-tag';
import { DONBOT_LIST_QUERY, } from '@haaretz/graphql';
import DonbotView from './DonbotView';
import ListWrapper from '../../ListWrapper';

const DonbotQuery = gql`
  query DonbotQuery($input: ListInput!) {
    list(input: $input) {
      dedupeReadingHistory
      isLazyloadImages
      title
      url
      preventRender {
        from
        until
      }
      commercialLinks {
        href
        contentName
        contentId
      }
      extraLinks {
        href
        contentName
        contentId
      }
      marketingTeaser {
        title
        subtitle
        href
        cta
      }
      items {
        ... on Teaser {
          contentId
          hash
          representedContent
          title
          titleMobile
          subtitle
          subtitleMobile
          exclusive
          exclusiveMobile
          path
          rank
          commentsCounts
          publishDate
          inputTemplate
          ...ImageInTeaser
          authors {
            contentName
          }
        }
      }
    }
  }
  ${imageInTeaser}
`;

export { DonbotQuery, };

// eslint-disable-next-line import/no-extraneous-dependencies

type DonbotWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function DonbotWrapper(props: DonbotWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={DONBOT_LIST_QUERY} view="Donbot">
      {dataProps => <DonbotView {...dataProps} />}
    </ListWrapper>
  );
}
